/*$pipaFontSize: 14px;*/
/*$pipaFontColor: #000;*/
/*$pipaColorTheme: #387EE8;*/
/*$pipaBackgroundColor: #1242c9;*/

.investor-img {
  background: url(../img/180717/investor.png) no-repeat center center;
  height: 540px;
}
.investor-title {
  text-align: center;font-size: 36px;color: #373D41;letter-spacing: 1.5px;
}

.titleLine {
  display: inline-block;
  width: 50px;
  height: 8px;
  margin: 30px 0 20px;
  opacity: 0.4;
  background: #387EE8;
}

.h4SubTitle1 {
  font-size: 18px;
  color: #5F6367;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}

.h4Content1 {
  font-size: 18px;
  color: #002059;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 20px;
  font-weight: 400;
  text-align: center;
}

.h4CoreContent {
  font-size: 16px;
  color: #5F6367;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 400;
}

.valueItem {
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.20);
  padding: 20px;
}

.core12 {
  height: 200px;
  margin-top: 50px;
  background: #FFFFFF;
}

.coreItem {
  height: 200px;
  width: 20%;
  border-right: 1px solid #EEEEEE;
}

.coreItem-last-child {
  width: 40%;
  /*margin-right: 10px;*/
  /*margin-left: 10px;*/
}

.investor-cpm-theImage{
  background: url(../img/180717/cpm.png) no-repeat center center;
  background-size: 580px;
  height: 385px;
}

.investor-spm-theImage{
    background: url(../img/180717/spm.png) no-repeat center center;
    height: 336px;
    background-size: 440px;
    background-position-x: -40px;
}


.investor-ida-theImage{
  background: url(../img/180717/ida.png) no-repeat center center;
  height: 340px;
  background-size: 340px;
}

.investor-cis-theImage {
  background: url(../img/180717/cis.png) no-repeat center center;
  height: 336px;
  background-size: 336px;
  background-position-x: 150px;
  margin-top: 35px;
}

.core-img1 {
  margin-top: 35px;
  height: 60px;
  background: url(../img/180717/core1.png) no-repeat center center;
  background-size: 63px 60px;
}

.core-img2 {
  margin-top: 35px;
  height: 60px;
  background: url(../img/180717/core2.png) no-repeat center center;
  background-size: 57px 60px;
}

.core-img3 {
  margin-top: 35px;
  height: 60px;
  background: url(../img/180717/core3.png) no-repeat center center;
  background-size: 69px 60px;
}

.core-img4 {
  margin-top: 35px;
  height: 60px;
  background: url(../img/180717/core4.png) no-repeat center center;
  background-size: 60px 60px;
}

.core-bg {
  margin-top: 35px;
  height: 57px;
  background: url(../img/180717/core_bg.png) no-repeat center center;
  background-size: 1400px;
  background-position-y: -5px;
}

.important-img {
  background: url(../img/180717/important.png) no-repeat center center;
  background-size: 100% 100%;
  padding: 0 80px 0 80px;
}

.workspace-content {
  padding: 10px;
}

a {
  cursor: pointer;
}

.navbar-fixed-top, .navbar-fixed-bottom {
  z-index: 900!important;
}

.content1300 {
  padding-left: calc((100% - 1300px) / 2);
  padding-right: calc((100% - 1300px) / 2);
}

.content1400 {
  padding-left: calc((100% - 1400px) / 2);
  padding-right: calc((100% - 1400px) / 2);
}

form input.error {
  border-color: red;
}
form label.error {
  padding-top: 5px;
  color: red;
}
.form-group .form-control {
  padding: 0px 5px;
  box-shadow: none;
}
.form-control[readonly] {
  cursor: pointer;
  background-color: #fff;
}

.ui-chkbox-label {
  vertical-align: top !important;
}
.ui-widget-overlay {
  background: transparent!important;
}
p-dialog .ui-widget-header, p-confirmdialog .ui-widget-header {
  background: #1530C9;
  color: #fff;
}
p-dialog .ui-dialog .ui-dialog-titlebar-icon, p-confirmdialog .ui-dialog .ui-dialog-titlebar-icon {
  color: #fff;
}

.ui-dialog.ui-widget .ui-dialog-titlebar {
  padding: .55em .65em!important;
}
.ui-dialog.ui-widget .ui-dialog-content {
  padding: 1em 1.5em;
}
.ui-dialog-footer {
  padding: .6em;
}
.ui-panel .ui-panel-content {
  padding: 0!important;
}
.ui-widget-content {
  border: none;
}
.ui-panel.ui-widget .ui-panel-titlebar {
  border-width: 1px;
}
.ui-widget.ui-button, .ui-button {
  border: 1px solid #1530C9;
  background: #1530C9;
}
.ui-widget.ui-button:hover, .ui-button:hover, .ui-widget.ui-button:focus, .ui-button:focus {
  border: 1px solid #00269F!important;
  background: #00269F!important;
}
.ui-fieldset {
  border: 1px solid #D5D5D5;
}

.cdk-overlay-container {
  z-index: 3000;
}
legend {
  width: auto!important;
  font-size: $pipaFontSize!important;
}

.table {
  margin-bottom: 0!important;
}

.marginLeft5 {
  margin-left: 5px;
}
.marginLeft10 {
  margin-left: 10px;
}
.marginBottom10 {
  margin-bottom: 10px;
}
.marginBottom20 {
  margin-bottom: 20px;
}
.marginTop10 {
  margin-top: 10px;
}
.marginTop20 {
  margin-top: 20px;
}

.services h4, .about h4 {
  line-height: 1.8em;
  font-size: 16px
}

.mytable {
  margin: 0px !important;
  padding: 0px !important;
}

.mytd {
  padding: 1px 0px !important;
  border: 0 !important;
  width: 50%;
  color: black;
  font-weight: 400;
}
.title-td {
  border-top: none!important;
  padding: 0!important;
}
.title-span {
  font-weight: 600;
  font-size: 16px;
}

.ace_breakpoint {
  color: white;
  border-image: url(../img/breakpoint.png) 1 4 1 1 fill;
  border-style: solid;
  margin: 0px;
  border-width: 1px 4px 1px 1px;
  padding-left: 28px!important;
}
.ace_runLineMarker {
  position: absolute;
  background: rgba(100, 200, 100, 0.5);
  z-index: 20
}
.ace_logTime {
  color: #66D9EF;
}
.ace_logMethod {
  color: #258ccd;
}
.ace_logWarn {
  color: #B58900;
}
.ace_logSystem {
  color: red;
}

.debug-result-content .fold-view {
  display: none;
}
.debug-result-content .ex-alia {
  display: inline-block;
  color: #25aae2;
  padding: 0 2px;
}
.debug-result-content .key {
  color: #bf268d;
}
.debug-result-content .val-Boolean {
  color: darkorange;
}
.debug-result-content .val-Number {
  color: #5b47da;
}
.debug-result-content .val-String {
  color: #c41a16;
}
.debug-result-content .val-Null {
  color: #8B8b8b;
}
.debug-result-content > ul {
  padding: 0;
  line-height: 16px;
}
.debug-result-content > ul ul {
  padding-left: 20px;
  line-height: 16px;
}
.debug-result-content .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  fill: #000;
}
.debug-result-content .icon-square {
  width: 12px;
  height: 12px;
  display: inline-block;
  cursor: pointer;
  user-select: none
}
.debug-result-content .icon-square-plus {
  display: none;
  user-select: none
}

.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid;
  border-color: #cccccc;
  position: relative;
  text-align: left;
  overflow: hidden;
  line-height: 8px;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: #428bca;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background: #5bc0de;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: #5cb85c;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  background: #f0ad4e;
  color: #fff;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: #d9534f;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #000;
  background: #eeeeee;
}
.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 100;
  color: #333333;
  background: #ffffff;
}
.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.bootstrap-switch .bootstrap-switch-handle-off {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}
.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
  height: auto;
}
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 6px 16px;
  font-size: 18px;
  line-height: 1.33;
}
.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.5s;
  transition: margin-left 0.5s;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.bootstrap-switch.bootstrap-switch-focused {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.fa-trash-o {
  width: 14px;
}

/* Chrome, Safari, Edge, Opera: to remove spin buttons*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.margin-left-minus-100 {
  margin-left: -100px;
}

.paddingCardView {
  padding: 0 8px !important;
}

.topic-filters {
  text-align: right;
  padding: 20px 0;
}
.subject {
  border-bottom: 1px solid #E8ECF0;
  padding: 10px 0;
}
. last-child {
  margin-bottom: 20px;
}
.subject-title {
  font-size: 15px;
  line-height: 2rem;
  font-weight: 800;
  cursor: pointer;
}
.subject-props {
  margin-top: 20px;
}

.col-md-1, .col-md-2, .col-md-3, .col-md-6 {
  padding: 0;
}
.col-md-6 {
  text-align: center;
}
.col-md-1, .col-md-2 {
  text-align: right;
}

.topic-view, .topic-star {
  margin-right: 5px;
  color: #387EE8;
}

.detail-title {
  text-align: center;
  font-size: 15px;
  font-weight: 800;
  padding: 20px 0 10px;
  line-height: 2rem;
}
.detail-desc {
  border-bottom: 1px solid #E8ECF0;
  padding-bottom: 15px;
}
.col-md-3, .col-md-6 {
  padding: 0;
}
.col-md-6 {
  text-align: center;
}
.col-md-3:last-child {
  text-align: right;
}
.detail-button-split {
  margin: 0 20px;
}
.detail-star {
  cursor: pointer;
}
i {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 900;
}
/*.active, hover {*/
/*  color: green;*/
/*  background-color: #1242c9;*/
/*}*/

.topic-answer {
  border-bottom: 1px solid #E8ECF0;
}
topic-answer-last-child {
   margin-bottom: 20px;
}

.answer-comment {
  border-bottom: 1px dotted #E8ECF0;
  margin-bottom: 10px;
}
answer-comment-last-child {
 border-bottom: none;
 margin-bottom: 0;
 margin-left: 60px;
}
.col-md-3, .col-md-6 {
  padding: 0;
}
.col-md-6 {
  text-align: center;
}
.col-md-3:last-child {
  text-align: right;
}
.answer-comment + .answer-comment {
  border-top: none;
}

.operateItem {
  cursor: pointer;
  text-decoration: underline;
  color: #387EE8;
}

.operateItem {
  cursor: pointer;
  text-decoration: underline;
  color: #387EE8;
}

.font-desc {
  font-family: 'Open Sans', Arial, sans-serif, '\5FAE\8F6F\96C5\9ED1';
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3em;
  color: #8997AB;
}

.score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.riskAssessmentPanel {
  /*background: #fff;*/
  /*padding: 10px;*/
  margin-top: 10px;
}

.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 10px;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
}

.question-text-font {
  font-size: 18px;
  font-weight: 500;
  color: #2d3748;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quizButton {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #234668;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}

.quizButtonHighlighted {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #234668;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid red;
  cursor: pointer;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

button:hover {
  background-color: #555e7d;
}

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}
